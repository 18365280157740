import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js'
import { mergeVertices } from 'three/addons/utils/BufferGeometryUtils.js'
import CustomShaderMaterial from 'three-custom-shader-material/vanilla'
import GUI from 'lil-gui'
import wobbleVertexShader from './shaders/wobble/vertex.glsl'
import wobbleFragmentShader from './shaders/wobble/fragment.glsl'
import { FontLoader } from 'three/examples/jsm/Addons.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

let model1


/**
 * Base
 */
// Debug
// const gui = new GUI({ width: 325 })
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Loaders
const rgbeLoader = new RGBELoader()
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('./draco/')
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)
const textureLoader = new THREE.TextureLoader()

let mixer = null

/**
 * Model
 */
gltfLoader.load(
    '/model/untitled1_6_1.glb',            
    (gltf) =>
    {
        model1 = gltf.scene
        model1.scale.set(1, 1, 1)
        model1.rotation.x = Math.PI / 2
        model1.position.set(0,0,-0.5)
        scene.add(model1)

        // const material = new THREE.MeshPhysicalMaterial({
        //     metalness: 5,
        //     roughness: .05,
        //     clearcoat: 10,
        //     opacity: .5,
        //     reflectivity: 10,
        //     refractionRatio: 0.985,
        //     ior: 0.9,
            
        //     })

        //     const model1 = new THREE.Mesh (textGeometry, material)
    })

/**
 * Environment map
 */
// rgbeLoader.load('./urban_alley_01_1k.hdr', (environmentMap) =>
// {
//     environmentMap.mapping = THREE.EquirectangularReflectionMapping

//     scene.background = environmentMap
//     scene.environment = environmentMap
// })

/**
 * Background
 */
debugObject.backgroundColor = '#0008ff'; // Start with black background
scene.background = new THREE.Color(debugObject.backgroundColor);


/**
 * Wobble
 */
// Material
debugObject.colorA = '#002aff'
debugObject.colorB = '#ffffff'

const uniforms = {
    uTime: new THREE.Uniform(0),
    uPositionFrequency: new THREE.Uniform(0.876),
    uTimeFrequency: new THREE.Uniform(0.152),
    uStrength: new THREE.Uniform(0.054),
    uWarpPositionFrequency: new THREE.Uniform(0.065),
    uWarpTimeFrequency: new THREE.Uniform(0.146),
    uWarpStrength: new THREE.Uniform(0.438),
    uColorA: new THREE.Uniform(new THREE.Color(debugObject.colorA)),
    uColorB: new THREE.Uniform(new THREE.Color(debugObject.colorB))
}

const material = new CustomShaderMaterial({
    // CSM
    baseMaterial: THREE.MeshPhysicalMaterial,
    vertexShader: wobbleVertexShader,
    fragmentShader: wobbleFragmentShader,
    uniforms: uniforms,
    silent: true,

    // MeshPhysicalMaterial
    metalness: 0.065,
    roughness: 0,
    color: '#f2f2ff',
    transmission: 1,
    ior: 0.8,
    thickness:10,
    transparent: true,
    wireframe: false
})

const depthMaterial = new CustomShaderMaterial({
    // CSM
    baseMaterial: THREE.MeshDepthMaterial,
    vertexShader: wobbleVertexShader,
    uniforms: uniforms,
    silent: true,

    // MeshDepthMaterial
    depthPacking: THREE.RGBADepthPacking
})


// Tweaks
// gui.add(uniforms.uPositionFrequency, 'value', 0, 2, 0.001).name('uPositionFrequency')
// gui.add(uniforms.uTimeFrequency, 'value', 0, 2, 0.001).name('uTimeFrequency')
// gui.add(uniforms.uStrength, 'value', 0, 2, 0.001).name('uStrength')
// gui.add(uniforms.uWarpPositionFrequency, 'value', 0, 2, 0.001).name('uWarpPositionFrequency')
// gui.add(uniforms.uWarpTimeFrequency, 'value', 0, 2, 0.001).name('uWarpTimeFrequency')
// gui.add(uniforms.uWarpStrength, 'value', 0, 2, 0.001).name('uWarpStrength')
// gui.addColor(debugObject, 'colorA').onChange(() => uniforms.uColorA.value.set(debugObject.colorA))
// gui.addColor(debugObject, 'colorB').onChange(() => uniforms.uColorB.value.set(debugObject.colorB))
// gui.add(material, 'metalness', 0, 1, 0.001)
// gui.add(material, 'roughness', 0, 1, 0.001)
// gui.add(material, 'transmission', 0, 1, 0.001)
// gui.add(material, 'ior', 0, 10, 0.001)
// gui.add(material, 'thickness', 0, 10, 0.001)
// gui.addColor(debugObject, 'backgroundColor').onChange(() => {scene.background = new THREE.Color(debugObject.backgroundColor);});

// Geometry
let geometry = new THREE.IcosahedronGeometry(2.5, 50)
geometry = mergeVertices(geometry)
geometry.computeTangents()

// Mesh
const wobble = new THREE.Mesh(geometry, material)
wobble.customDepthMaterial = depthMaterial
wobble.receiveShadow = true
wobble.castShadow = true
scene.add(wobble)


/**
 * Fonts
 */

// Texture loader
// const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load
(
    // 'textures/matcaps/Diamond/14.png',
    // 'textures/matcaps/Diamond/12.png',
    // 'textures/matcaps/Iridescent/5.png',
    'textures/matcaps/3.png',

)
matcapTexture.colorSpace = THREE.SRGBColorSpace

// Font
const fontLoader = new FontLoader()
let Text = 
    `

    `

fontLoader.load(
    'fonts/helvetiker_regular.typeface.json',
    // 'fonts/static/fonts/Serifbabe Alpha Trial.json',

    (font)=>
        {
            // const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })

            const material = new THREE.MeshPhysicalMaterial({
                metalness: 5,
                roughness: .05,
                clearcoat: 10,
                opacity: .5,
                reflectivity: 10,
                refractionRatio: 0.985,
                ior: 0.9,
                
                })

            const textGeometry = new TextGeometry(
                Text,
                {
                font: font,
                size: 0.3,
                height: 0.001,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.005,
                bevelSize: 0.002,
                bevelOffset: 0,
                bevelSegments: 9
                }
            )
            

            const text = new THREE.Mesh (textGeometry, material)
            text.position.x = -2.55
            text.position.y = 0.95
            text.rotation.x = Math.PI *2
            scene.add(text)
        
        }
)

/**
 * Plane
 */

const points = [];
for ( let i = 0; i < 10; i ++ ) {
	points.push( new THREE.Vector2( Math.sin( i * 0.2 ) * 10 + 5, ( i - 5 ) * 2 ) );
}
const lathegeometry = new THREE.LatheGeometry(points);
const lathegeometrymaterial = new THREE.MeshBasicMaterial({
    color: 0xFFFAFA,
    wireframe: true 
});
const lathe = new THREE.Mesh(lathegeometry, lathegeometrymaterial);

lathe.receiveShadow = true
lathe.rotation.y = Math.PI/180*1
lathe.rotation.z = Math.PI/180*1
lathe.rotation.x = Math.PI/180*89
lathe.position.x = -14
lathe.position.y = 0
lathe.position.z = -10
scene.add( lathe )


const points1 = [];
for ( let i = 0; i < 10; i ++ ) {
	points1.push( new THREE.Vector2( Math.sin( i * 0.2 ) * 10 + 5, ( i - 5 ) * 2 ) );
}
const lathegeometry1 = new THREE.LatheGeometry(points);
const lathegeometrymaterial1 = new THREE.MeshBasicMaterial({
    color: 0xFFFAFA,
    wireframe: true 
});
const lathe1 = new THREE.Mesh(lathegeometry1, lathegeometrymaterial1);

lathe1.receiveShadow = true
lathe1.wireframe = true
lathe1.rotation.y = Math.PI*180
lathe1.rotation.z = Math.PI/180*1
lathe1.rotation.x = Math.PI/180*89
lathe1.position.x = 15.5
lathe1.position.y = 0
lathe1.position.z = -6
scene.add( lathe1 )



/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.normalBias = 0.05
directionalLight.position.set(0.25, 2, - 2.25)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerHeight,
    height: window.innerHeight,
    pixelRatio: Math.min(window.devicePixelRatio, 2)

    // width: 500,  // Set width to 500px
    // height: 500, // Set height to 500px
    // pixelRatio: Math.min(window.devicePixelRatio, 2)
}

window.addEventListener('resize', () =>
{
    // // Update sizes
    sizes.width = window.innerHeight
    sizes.height = window.innerHeight
    sizes.pixelRatio = Math.min(window.devicePixelRatio, 2)

    // Update sizes
    // sizes.width = 500;
    // sizes.height = 500;
    // sizes.pixelRatio = Math.min(window.devicePixelRatio, 2)

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(sizes.pixelRatio)
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.set(-0.05, 0, 3.5)
camera.lookAt(0,0,0);


scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.dampingFactor = 0.25;
controls.enableZoom = false;
controls.enablePan = false;
controls.autoRotate = false;
controls.autoRotateSpeed = 0.1;

controls.minPolarAngle = 0; // 0 degrees
controls.maxPolarAngle = Math.PI; // 180 degrees

controls.minAzimuthAngle = -Math.PI / 4; // -45 degrees
controls.maxAzimuthAngle = Math.PI / 4; // 45 degrees

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.toneMappingExposure = 1
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(sizes.pixelRatio)

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Materials
    uniforms.uTime.value = elapsedTime

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

